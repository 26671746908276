<template>
  <div id="gallery-album">
    <app-menu></app-menu>
    <admin-list-component
        id="gallery"
        title="Galerie"
        add-text="Přidat album"
        edit-path="/admin/album/edit/"
        entity-name="album"
        :get-name-function="getName"
        draggable="true"
    />
  </div>
</template>

<script>
import AdminMenu from "@/components/admin/AdminMenu";
import AdminListComponent from "@/components/admin/AdminListComponent";


export default {
  name: "galleryAlbum",
  components: {
    'app-menu': AdminMenu,
    'admin-list-component': AdminListComponent
  },
  methods: {
    getName: function (entity) {
      return entity.name
    }
  }
}
</script>

<style scoped>
#gallery {
  min-height: 100vh;
}
</style>
